<template>
  <v-container
    id="payment"
    class="fill-height justify-center"
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="9"
      >
        <v-row justify="center">
          <v-slide-y-transition appear>
            <base-material-card
              color="primary"
              light
              max-width="90%"
              min-width="80%"
            >
              <template #heading>
                <div class="text-center">
                  <h1 class="display-2 font-weight-bold">
                    {{ $tc('payment', 1) }}
                  </h1>
                </div>
              </template>

              <v-overlay :value="loading">
                <v-progress-circular
                  indeterminate
                  absolute
                  size="64"
                />
              </v-overlay>

              <v-card-text>
                <v-row
                  class="mx-auto"
                  justify="space-around"
                  style="max-width: 500px;"
                >
                  <v-col cols="12">
                    <div class="text-center display-1 font-weight-light">
                      {{ $tc('wizards.payment', 1) }}
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <div class="body-1">
                      {{ $tc("status", 1) }} {{ $tc("authorization", 1) }}: <span v-if="payment">{{ $tc("statuses." + payment.status.name, 1) }}&nbsp;
                        <v-btn
                          v-if="payment.status.name !== 'paid'"
                          class="white--text"
                          color="grey darken-2"
                          x-small
                          @click="statusPayment()"
                        >
                          <span
                            class="hidden-sm-and-down ml-1"
                            v-text="$tc('refresh', 1)"
                          />
                          <v-icon
                            right
                            x-small
                          >
                            mdi-refresh
                          </v-icon>
                        </v-btn>
                      </span><span v-else>...</span>
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <div class="body-2">
                      {{ $tc('recurringfollowup', 1) }}
                    </div>
                  </v-col>

                  <v-col
                    cols="6"
                    class="d-flex justify-center align-center"
                  >
                    <a
                      href="https://apps.apple.com/nl/app/eazis-play/id1490231051"
                      target="_blank"
                    >
                      <v-img
                        :src="require(`@/my/assets/apple-app-store.png`)"
                        contain
                        height="40"
                      />
                    </a>
                  </v-col>
                  <v-col
                    cols="6"
                    class="d-flex justify-center align-center"
                  >
                    <a
                      href="https://play.google.com/store/apps/details?id=com.eazis.eazisplayer"
                      target="_blank"
                    >
                      <v-img
                        :src="require(`@/my/assets/google-play-store.png`)"
                        contain
                        height="40"
                      />
                    </a>
                  </v-col>

                  <v-col cols="12">
                    <div class="body-2">
                      {{ $tc('recurringlogin', 1) }}
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  class="white--text"
                  color="black"
                  min-width="125"
                  :to="{ name: 'Login' }"
                >
                  <v-icon>mdi-fingerprint</v-icon>
                  <span
                    class="hidden-sm-and-down ml-1"
                    v-text="$tc('login', 1)"
                  />
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </base-material-card>
          </v-slide-y-transition>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'PagesPayment',

    data: function () {
      return {
        loading: true,
        payment: null,
      }
    },
    created () {
      this.statusPayment()
    },
    methods: {
      statusPayment () {
        var self = this
        var router = this.$route
        self.loading = true
        self.$http.get('/api/subscription/payment/feedback/' + router.params.ssid).then(function (response) {
          self.payment = response.data
          self.loading = false
        }, function (response) {
          self.loading = false
        })
      },
    },
  }
</script>

<style lang="sass">
  .v-card.v-card.v-card--account
    border-color: currentColor
    border-width: 4px

    .v-icon
      color: inherit

  .v-card--account,
  .v-card--account:before
    border-radius: 50%
</style>
